<template>
  <div id="app" ref="app">
    <div ref="content" class="content">
    <!-- <b-row>
        <b-col>
          <div class="text-center">
            needle: {{ this.needle }}
            <br />
            pivots: {{ this.current_pivots }}
          </div>
      </b-col>
    </b-row> -->
      <b-row no-gutters>
        <b-col>
          <b-row no-gutters>
            <b-col class="mt-2 ml-2">
              <b-form-group class="w-100">
                <b-form-radio-group
                  buttons
                  button-variant="outline-primary"
                  size="sm"
                  id="method"
                  v-model="includeForms"
                  class="under-trick m-0 w-100"
                >
                  <b-form-radio class="" value="false">Exact</b-form-radio>
                  <b-form-radio class="" value="true">All Forms</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col class="ml-2">
              <b-form-group class="w-100">
                <!-- <div class="text-center">
                  Where:
                </div> -->
                <b-form-radio-group
                  buttons
                  button-variant="outline-primary"
                  size="sm"
                  id="method"
                  v-model="includeSentences"
                  class="under-trick m-0 w-100"
                >
                  <b-form-radio class="" value="false">Phrase</b-form-radio>
                  <b-form-radio class="" value="true">Phrase & Sentence</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
            <!-- <b-col class="d-flex justify-content-center"> -->
        <b-col>
          <b-row no-gutters>
            <b-col class="p-0 m-0">
              <b-row no-gutters>
                <b-col class="text-center">
                  <small>Words to Search: <b>{{matchCount}}</b></small>
                </b-col>
              </b-row>
              <b-row no-gutters>
                <b-col class="text-center m-0 p-0">
                  <b-form-input class="w-75" id="match" v-model="matchCount" type="range" min="1" max="3"></b-form-input>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row no-gutters class="text-center">
            <!-- <b-col class="d-flex justify-content-center"> -->
            <b-col cols="1"></b-col>
            <b-col cols="3">
              <div class="cursor-pointer">
                <b-form-group class="m-0 p-0 cursor-pointer">
                  <label class="d-block mb-0 cursor-pointer lh-1" for="rank">Show Rank&nbsp;</label>
                  <b-form-checkbox v-model="showRank" id="rank" class="d-inline m-0 pt-1 cursor-pointer" inline></b-form-checkbox>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="3">
              <div class="cursor-pointer">
                <b-form-group class="m-0 p-0 cursor-pointer">
                  <label class="d-block mt-0 mb-0 lh-1 cursor-pointer" for="expand">Expand All&nbsp;</label>
                  <b-form-checkbox v-model="expandAll" id="expand" class="d-inline m-0 pt-1 cursor-pointer" inline></b-form-checkbox>
                </b-form-group>
              </div>
            </b-col>
            <b-col cols="5">
              <div class="cursor-pointer">
                <b-form-group class="m-0 p-0 cursor-pointer">
                  <label class="d-block mt-0 mb-0 lh-1 cursor-pointer" for="jp">Show Japanese&nbsp;</label>
                  <b-form-checkbox v-model="expandJP" id="jp" class="d-inline m-0 pt-1 cursor-pointer" inline></b-form-checkbox>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="border-top" no-gutters>
        <b-col class="p-1">
          <div v-if="clampedResults.length < 1">
            <div id="no_results">
              No Results
            </div>
          </div>
          <div v-else v-for="res in clampedResults" v-bind:key="res">
            <div class="block">
              <div class="line">
                <span v-if="showRank" class="rank">#{{ lines[res][0] }}</span>
                {{ lines[res][4] }}
              </div>
              <div class="example" :class="expandAll ? 'show_block' : ''">
                <!-- {{ lines[res][5] }} -->
                <div v-html="computedExample(lines[res][5])"></div>
                <!-- <br /> -->
                <!-- <div>{{ lines[res][6] }}</div> -->
                <div class="jp" :class="expandJP ? 'show_block' : ''">{{ lines[res][6] }}</div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<style>
  * {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
  .block {
    padding-bottom: 0.25em;
    padding-right: 1.5em;
  }
  .debug * {
    border: 1px solid red !important;
  }
  label {
    font-size: 14px;
  }
  .cursor-pointer {
    cursor: pointer !important;
  }
  #y {
    background-color: lemonchiffon;
  }
  .line {
    /* cursor: pointer; */
    /* font-size: 110%; */
    /* padding-left: 1em;
    padding-right: 1em; */
  }
  /* .line:hover {
    font-weight: bold;
  } */
  .rank {
    font-size: small;
    font-weight: bold;
  }
  .example {
    border-top: 1pt dotted #333;
    font-size: 100%;
    font-style: italic;
    border-bottom: 1pt solid #333;
    display:none;
  }
  .show_block {
    display: block !important;
  }
  .block:hover {
    background: #fafafa;
  }
  .block:hover .example {
    display: block;
  }
  .block:hover .line {
    /* font-weight: bold; */
  }
  #no_results {
    color: #aaa;
    font-size: 150%;
    text-align: center;
    margin-top: 1em;
  }

  .under-trick .active {
    text-decoration: underline;
    font-weight: bold;
  }

  /* .col {
    border: 1px solid red;
  } */
  .jp {
    display: none;
  }
  .lh-1 {
    line-height: 1
  }
</style>

<script>
import ac from '@/ac.json'
import piv from '@/pivots.json'
/* eslint-disable no-unused-vars */

const RANK = 0
const OCCURS = 1
const PIVOT = 2
const COLLOCATE = 3
const PHRASE = 4
const SENTENCE = 5
const JP = 6

export default {
  name: 'App',
  data() {
    return {
      includeSentences: 'true',
      includeForms: 'true',
      showRank: true,
      expandAll: true,
      expandJP: true,
      matchCount: Number(1),
      debug: '',
      message: '',
      lines: ac,
      lc_lines: [],
      pivots: piv,
      needle: '',
      cursor: {},
      maxVisible: 10,
      start: Number(),
      end: Number(),
      results: Array(),
      old_text: '',
      intv_cursor: Number(),
      debounce_load: 0,
      activeHover: Number(),
      current_pivots: [],
    }
  },
  mounted() {
    this.lc_lines = this.lines.map(l => {
      l[SENTENCE] = l[SENTENCE].toLowerCase()
      l[PHRASE] = l[PHRASE].toLowerCase()
      return l
    })
    window.addEventListener('scroll', this.loadMore)
    this.intv_cursor = setInterval(() => {
      window.Word.run(async (context) => {
        let cursor = context.document.getSelection()
        context.load(cursor)
        await context.sync()
        context.load(cursor.paragraphs)
        await context.sync()
        
        // must call isEmpty to check if it's a cursor
        if (cursor.isEmpty) {
          if (cursor.paragraphs) {
            if (cursor.paragraphs.items) {
              if (cursor.paragraphs.items[0]) {
                if (cursor.paragraphs.items[0].text) {
                  if (this.old_text !== cursor.paragraphs.items[0].text) {
                    this.cursor = cursor.paragraphs.items[0]
                    this.old_text = cursor.paragraphs.items[0].text
                    this.search(cursor.paragraphs.items[0].text)
                  }
                }
              }
            }
          }
        }
        else {
          // it's a selection
          this.needle = ''
          this.results = []
        }
      })
    }, 400)
  },
  destroyed() {
    window.removeEventListener('scroll', this.loadMore);
    clearInterval(this.intv_cursor)
  },
  computed: {
    clampedResults() {
      return this.results.slice(0, this.maxVisible)
    }
  },
  methods: {
    loadMore() {
      clearTimeout(this.debounce_load)
      this.debounce_load = setTimeout(() => {
        // there is a very small bug here where it will auto-set to 20 because this condition is always true when the height is lower than 100vh
        if (parseInt(document.documentElement.scrollHeight - window.scrollY) <= parseInt(document.documentElement.clientHeight)) {
          this.maxVisible = this.maxVisible + 10
        }
      }, 50)
    },
    variate(array) {
      var indices = new Array(array.length).fill(0);
      var builder = "";
      var done = false;
      let out = []

      while (!done) {
        builder = "";

        // Build the combination using the current indices
        for (let i = 0; i < array.length; i++) {
          var subArray = array[i][indices[i]];
          for (let j = 0; j < subArray.length; j++) {
            builder += subArray[j]
          }
          builder += ' '
        }

        out.push(builder)

        // Increment the indices from right to left
        var index = indices.length - 1;
        while (index >= 0 && indices[index] == array[index].length - 1) {
          indices[index] = 0;
          index--;
        }

        if (index < 0) {
          // All indices have overflowed, so we're done
          done = true;
        } else {
          // Increment the current index and all subsequent indices
          indices[index]++;
          for (let i = index + 1; i < indices.length; i++) {
            indices[i] = 0;
          }
        }
      }
      return out
    },
      generatePivots(words) {
        // make pivot tables
        let pt = []
        for(let w of words) {
          if (this.pivots[w]) {
            pt.push([w, ...this.pivots[w]])
          }
          else {
            pt.push([w])
          }
        }

        return this.variate(pt).map(s => s.trim())
      },
      search(cursor) {
      // eventually we should debounce this I guess, or debounce the watchers
      this.start = performance.now()

      // reset clamp count
      this.maxVisible = 30

      // note: this.needle is an Array type
        // 160 is the nbsp space
      const dirty_line = cursor.replace(RegExp('[\u{00A0}|\u{200B}|\u{3000}]', "g"), ' ')
      // const dirty_line = cursor.replace(RegExp(String.fromCharCode(160), "g"), ' ')
      const line = dirty_line.replace(/[\u2018\u2019]/g, "'").replace(/\./g, '').replace(/,/g, '');
      const lastChar = line.charCodeAt(line.length - 1)
      // console.warn([...Array(line.length).keys()].map(i => line.charCodeAt(i)))

      if (line !== ' ' && lastChar == 32) {
        // check if we are at the start of the document
        if (line.indexOf(' ') + 1 === line.length) {
          this.needle = line.toLowerCase()
        }
        else {
          let offset = [...Array(parseInt(this.matchCount))].reduce((a,_) => line.lastIndexOf(' ', a - 1), line.length - 2)
          this.needle = line.substr(offset + 1, line.length).toLowerCase()
        }

        // find matching root for word and get a list of all pivots

        let pivots = [this.needle]
        if (this.includeForms === 'true') {
          // checks if there is more than one word
          let index = this.needle.indexOf(' ')
          if (index > 0 && index < (this.needle.length-1)) { // check if the line form is "sensible"
            pivots = [
              this.needle.trim(),
              this.generatePivots(this.needle.split(' ').map(w => w.trim()))
            ].filter(word => word).flat()
          }
          else {
            if (this.pivots[this.needle.trim()]) {
              pivots = [
                this.needle.trim(),
                this.pivots[this.needle.trim()].map(v => v+' ')
              ].flat()
            }
          }
        }
        // why do we need this?
        pivots[0] = pivots[0].trim()

        let temp_results = []
        
        // check for japanese characters
        if (this.expandJP === true) {
          for (let line in this.lines) {
            for (let pivot of pivots.map(p => p.trim())) {
              let jpIndex = this.lc_lines[line][JP].indexOf(pivot)
              if (jpIndex !== -1) {
                temp_results.push(line)
              }
            }
          }
        }

        if (this.includeSentences === 'true') {
          for (let line in this.lines) {
            // check if each pivot word exists as a word with a boundary
            for (let pivot of pivots.map(p => p.trim())) {
              let sIndex = this.lc_lines[line][SENTENCE].indexOf(pivot)
              let pIndex = this.lc_lines[line][PHRASE].indexOf(pivot)
              if (sIndex !== -1 || pIndex !== -1) {
                let pLeft = this.lc_lines[line][PHRASE].charCodeAt(pIndex - 1)
                let pRight = this.lc_lines[line][PHRASE].charCodeAt(pIndex + pivot.length)
                let sLeft = this.lc_lines[line][SENTENCE].charCodeAt(sIndex - 1)
                let sRight = this.lc_lines[line][SENTENCE].charCodeAt(sIndex + pivot.length)
                if (sIndex !== -1 && this.exact(sLeft, sRight)) {
                  temp_results.push(line)
                  break
                }
                else if (pIndex !== -1 && this.exact(pLeft, pRight)) {
                  temp_results.push(line)
                  break
                }
              }
            }
          }
        }
        else {
          for (let line in this.lines) {
            // handle case of full match
            for (let pivot of pivots.map(p => p.trim())) {
              let pIndex = this.lc_lines[line][PHRASE].indexOf(pivot)
              if (pIndex !== -1) {
                let pLeft = this.lc_lines[line][PHRASE].charCodeAt(pIndex - 1)
                let pRight = this.lc_lines[line][PHRASE].charCodeAt(pIndex + pivot.length)
                if (pIndex !== -1 && this.exact(pLeft, pRight)) {
                  temp_results.push(line)
                  break
                }
              }
            }
          }
        }

        this.current_pivots = pivots

        this.results = temp_results
        this.end = performance.now()
      }
      else {
        // weird line composition
        this.needle = ''
        this.results = []
        this.current_pivots = []
      }
    },
    // what does this do lol
    exact(a, b) {
      if(
        !(a > 64 && a < 123) && !(b > 64 && b < 123)
        ||
        !(a > 64 && a < 123) && isNaN(b)
        ||
        isNaN(a) && !(b > 64 && b < 123)
        ||
        isNaN(a) && isNaN(b)
      ) {
        return true
      }
      else { return false }
    },
    computedExample(example) {
      if(this.includeForms !== 'true') {
        let test = this.needle.trim()
        let r = new RegExp("("+test+")")
        return example.replace(r, "<u>$1</u>")
      }
      else {
        let f = example
        for (let p of this.current_pivots) {
          let t = p.trim()
          let r = new RegExp('\\b('+t+')\\b')
          f = f.replace(r, "<u>$1</u>")
        }
        return f
      }
    },
    insertText() {
    },
  },
  watch: {
    matchCount() {
      this.search(this.old_text)
    },
    includeSentences() {
      this.search(this.old_text)
    },
    includeForms() {
      this.search(this.old_text)
    },
  },
};
</script>


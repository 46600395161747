import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue';
import './plugins/bootstrap-vue'
import App from './App.vue';

/* eslint-disable no-undef */

Vue.config.productionTip = false;

window.Office.initialize = () => {
  new Vue({
    render: h => h(App),
  }).$mount('#app');
};

window.Office.onReady((info) => {
  if (!Office.context.requirements.isSetSupported('WordApi', '1.3')) {
      console.log('Sorry. The tutorial add-in uses Word.js APIs that are not available in your version of Office.');
  }
  console.log(info)
})